interface Icon {
  code: string;
  description: string;
}

export interface RoomModel {
  name: string;
  imgs: Array<string>;
  singleBeds?: number;
  doubleBeds?: number;
  sofas?: number;
  armchairs?: number;
  bunkBeds?: number;
  combinableSingleBeds?: number;
}

export const contactPage = {
  header: {
    img: "pictures/Podwórko/IMG_0874.jpg",
    h1: "KONTAKT",
  },
  phoneNumber: `+48\u00A0605\u00A0548\u00A0729`,
  email: "biuro@owjodełka.pl",
  address: "Teligi 28, 84-360 Łeba",
};

export const mainPage = {
  carousel: {
    imgs: [
      "pictures/Podwórko/IMG_0862.jpg",
      "pictures/Podwórko/IMG_0872.jpg",
      "pictures/Podwórko/IMG_0858.jpg",
    ],
    h1: "OŚRODEK WYPOCZYNKOWY JODEŁKA",
    h2: "Zapraszamy",
  },
  about: {
    h1: "O nas",
    text: `Nasz ośrodek położony jest na spokojnym osiedlu domków jednorodzinnych, z dala od zatłoczonych ulic Łeby i sezonowego zgiełku. Oferujemy Państwu pokoje 1, 2, 3 i 4 osobowe z łazienkami o powierzchni od 9 do 25m${String.fromCharCode(
      178
    )}.
    Na terenie ośrodka do dyspozycji gości znajdują się w pełni wyposażone aneksy kuchenne. Z myślą o najmłodszych przygotowaliśmy plac zabaw o powierzchni około 100m${String.fromCharCode(
      178
    )}, znajduje się tam też altanka z dużym kamiennym grillem. Do dyspozycji gości udostępniamy stół tenisowy i dodatkowe grille. Można też bezpłatnie korzystać z rowerów. 
    Obydwa budynki otacza ogród z dużą ilością kwiatów i krzewów, co zapewnia odpoczynek i dobre samopoczucie. W cieniu parasoli, usadowieni na wygodnym meblach ogrodowych możecie zacząć dzień od wypicia kawy i zjedzenia śniadania na świeżym powietrzu.
    `,
    img: "pictures/7/IMG_0831.jpg",
  },
  locale: {
    h1: "Dogodna lokalizacja",
    text: "Położeni jesteśmy 800 m w linii prostej od plaży i 150 m od centrum miasta. Znajdziecie u nas dobrą bazę wypadową do wszystkich atrakcji jakie oferuje Łeba:",
    list: [
      "Wydmy",
      "Plaża",
      "Park dinozaurów",
      "Oceanarium",
      "Park linowy",
      "I wiele innych...",
    ],
    img: "/pictures/Podwórko/IMG_0906.jpg",
  },
  site: {
    h1: "Udogodnienia na terenie obiektu",
  },
  reservation: `W celu dokonania rezerwacji prosimy o kontakt telefoniczny po numerem ${contactPage.phoneNumber}.
  Aby potwierdzić możliwość przyjazdu należy dokonać wpłaty zadatku na konto bankowe. 
  Zadatek prosimy przelać w ciągu 2 dni od dokonania rezerwacji. 
  Zadatek w przypadku rezygnacji nie podlega zwrotowi. Możliwa jest także rezerwacja przez internet.`,
  roomsButtonImg: "/pictures/7/IMG_0830.jpg",
  contactButtonImg: "/pictures/Podwórko/IMG_0874.jpg",
};

export const roomPage = {
  header: {
    img: "pictures/23/IMG_0824.jpg",
    h1: "GALERIA",
  },
  info: "Oferujemy Państwu pokoje 1, 2, 3 i 4 osobowe z łazienkami o powierzchni od 9 do 25m². Dodatkowo w każdym pokoju znajduje się:",
  items: [
    { code: "Tv", description: "Telewizor" },
    { code: "Light", description: "Lampka nocna" },
    { code: "EmojiFoodBeverage", description: "Zastawa stołowa" },
    { code: "BeachAccess", description: "Parawan i koc na plażę" },
    { code: "DryCleaning", description: "Ręczniki" },
    { code: "Air", description: "Suszarka" },
    { code: "Kitchen", description: "Lodówka" },
    { code: "Shower", description: "Łazienka z prysznicem" },
  ],
  site: [
    "pictures/Podwórko/IMG_0856.jpg",
    "pictures/Podwórko/IMG_0864.jpg",
    "pictures/Podwórko/IMG_0873.jpg",
    "pictures/Podwórko/IMG_0883.jpg",
    "pictures/Podwórko/IMG_0892.jpg",
    "pictures/Podwórko/IMG_0858.jpg",
    "pictures/Podwórko/IMG_0866.jpg",
    "pictures/Podwórko/IMG_0874.jpg",
    "pictures/Podwórko/IMG_0884.jpg",
    "pictures/Podwórko/IMG_0894.jpg",
    "pictures/Podwórko/IMG_0860.jpg",
    "pictures/Podwórko/IMG_0870.jpg",
    "pictures/Podwórko/IMG_0875.jpg",
    "pictures/Podwórko/IMG_0889.jpg",
    "pictures/Podwórko/IMG_0906.jpg",
    "pictures/Podwórko/IMG_0862.jpg",
    "pictures/Podwórko/IMG_0872.jpg",
    "pictures/Podwórko/IMG_0878.jpg",
    "pictures/Podwórko/IMG_0891.jpg",
  ],
  rooms1: [],
  rooms2: [],
  rooms3: [
    {
      name: "5",
      imgs: [
        "pictures/5/IMG_0846.jpg",
        "pictures/5/IMG_0849.jpg",
        "pictures/5/IMG_0853.jpg",
      ],
      singleBeds: 1,
      sofas: 1,
    },

    {
      name: "22",
      imgs: [
        "pictures/22/IMG_0897.jpg",
        "pictures/22/IMG_0902.jpg",
        "pictures/22/IMG_0903.jpg",
        "pictures/22/IMG_0895.jpg",
      ],
      singleBeds: 1,
      sofas: 1,
    },
  ],
  rooms4: [
    {
      name: "4",
      imgs: [
        "pictures/4/IMG_0940.jpg",
        "pictures/4/IMG_0944.jpg",
        "pictures/4/IMG_0945.jpg",
      ],
      doubleBeds: 1,
      bunkBeds: 1,
    },
    {
      name: "7",
      imgs: [
        "pictures/7/IMG_0831.jpg",
        "pictures/7/IMG_0832.jpg",
        "pictures/7/IMG_0833.jpg",
        "pictures/7/IMG_0834.jpg",
        "pictures/7/IMG_0839.jpg",
        "pictures/7/IMG_0841.jpg",
        "pictures/7/IMG_0830.jpg",
      ],
      combinableSingleBeds: 2,
      sofas: 1,
    },
    {
      name: "17",
      imgs: [
        "pictures/17/IMG_0818.jpg",
        "pictures/17/IMG_0821.jpg",
        "pictures/17/IMG_0822.jpg",
        "pictures/17/IMG_0814.jpg",
        "pictures/17/IMG_0815.jpg",
      ],
      singleBeds: 1,
      combinableSingleBeds: 2,
      armchairs: 1,
    },
    {
      name: "23",
      imgs: [
        "pictures/23/IMG_0824.jpg",
        "pictures/23/IMG_0827.jpg",
        "pictures/23/IMG_0828.jpg",
      ],
      combinableSingleBeds: 2,
      sofas: 1,
    },
  ],
};
