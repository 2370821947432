import { lazy } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { routes } from "../../constants";
import { Layout } from "../Layout";

const Main = lazy(() => import("../../pages/Main"));
const Rooms = lazy(() => import("../../pages/Rooms"));
const Site = lazy(() => import("../../pages/Site"));
const Contact = lazy(() => import("../../pages/Contact"));

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: routes.root,
        element: <Main />,
      },
      {
        path: routes.rooms,
        element: <Rooms />,
      },
      {
        path: routes.site,
        element: <Site />,
      },
      {
        path: routes.contact,
        element: <Contact />,
      },
    ],
  },
]);

export function Routes() {
  return <RouterProvider router={router} />;
}
