import { Button, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../../constants";
import { Logo } from "../Logo";

interface HeaderListProps {}

export const Header = (props: HeaderListProps) => {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        position: "absolute",
        zIndex: "10",
        background:
          "linear-gradient(180deg, rgba(0 , 0, 0, 0.5)0%, rgba(0 , 0, 0, 0.5)80%, rgba(0,0,0,0.5) 100%)",
        height: "7rem",
        alignItems: "center",
      }}
      columns={{ xs: 4, md: 9 }}
    >
      <Grid
        item
        xs={1}
        md={1}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Logo />
      </Grid>
      <Grid item xs={1} md={1}>
        <Button
          component={NavLink}
          to={routes.root}
          sx={{
            "&.active": {
              textDecoration: "underline",
              textDecorationThickness: "3px",
              textDecorationColor: theme.palette.primary.main,
            },
            width: "100%",
          }}
        >
          <Typography noWrap>O nas</Typography>
        </Button>
      </Grid>
      <Grid item xs={1} md={1}>
        <Button
          component={NavLink}
          to={routes.rooms}
          sx={{
            "&.active": {
              textDecoration: "underline",
              textDecorationThickness: "3px",
              textDecorationColor: theme.palette.primary.main,
            },
            width: "100%",
          }}
        >
          <Typography noWrap>Galeria</Typography>
        </Button>
      </Grid>
      <Grid item xs={1} md={1}>
        <Button
          component={NavLink}
          to={routes.contact}
          sx={{
            "&.active": {
              textDecoration: "underline",
              textDecorationThickness: "3px",
              textDecorationColor: theme.palette.primary.main,
            },
            width: "100%",
          }}
        >
          <Typography noWrap>Kontakt</Typography>
        </Button>
      </Grid>
      <Grid item xs={0} md={2} />
    </Grid>
  );
};
