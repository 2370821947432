import { styled } from "@mui/material";
import { Colors } from "../../styles/theme";

export const Layout = styled("div")(({ theme }) => ({
  //   backgroundColor: theme.palette.grey[50],
  //   backgroundSize: "cover",
  //   backgroundPositionY: "100px",
  //   backgroundPositionX: "-300px",
  //   backgroundRepeat: "no-repeat",
  //   minHeight: `100vh`,
  //   flex: 1,
  //   display: "flex",
  //   justifyContent: "center",
  //   color: theme.palette.custom.textColors[800],
  //   marginTop: theme.spacing(10.5),
  // backgroundColor: Colors.dove_gray,
  // h1: {
  //   color: theme.palette.secondary.main,
  // },
}));
