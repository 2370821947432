import { Outlet } from "react-router-dom";
import { Header } from "../Header";
import * as Styled from "./styles";
import Footer from "../Footer";

export const Layout = () => (
  <Styled.Layout>
    <Header />
    <Outlet />
    <Footer />
  </Styled.Layout>
);
