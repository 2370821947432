import { ThemeProvider } from "@mui/material";

import theme from "./styles/theme";
import { Routes } from "./components/Routes";
import { Suspense } from "react";
import PageLoader from "./pages/PageLoader";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<PageLoader />}>
        <Routes />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
