import { createTheme } from "@mui/material";

export const Colors = {
  primary: "#68bf00",
  secondary: "#68bf00",
  success: "#4CAF50",
  info: "#00a2ff",
  danger: "#FF5722",
  warning: "#FFC107",
  dark: "#0e1b20",
  light: "#aaa",
  muted: "#abafb3",
  border: "#DDDFE1",
  inverse: "#2F3D4A",
  shaft: "#333",
  ///////////////
  // Grays
  ///////////////
  dim_grey: "#25291C", //"#696969",
  dove_gray: "#d5d5d5",
  body_bg: "#f3f6f9",
  light_gray: "rgb(230,230,230)",
  ///////////////
  // Solid Color
  ///////////////
  white: "#fff",
  black: "#000",
};

const theme = createTheme({
  palette: {
    primary: { main: Colors.primary },
    secondary: { main: Colors.secondary },
    white: { main: Colors.white },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        fontWeight: "bold",
        color: "white",
      },
    },
  },
  typography: {
    fontFamily: "Oswald",
    fontSize: 19,
    h1: { fontSize: "3rem", fontWeight: "bold" },
    h2: { fontSize: "3rem" },
    h3: { marginBottom: "1rem" },
  },
});

export default theme;
