import { Box, keyframes } from "@mui/material";
import React from "react";
import { Colors } from "../../styles/theme";
import { CircleLoader } from "react-spinners";

type Props = {};

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
export const PageLoader = (props: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: Colors.dim_grey,
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircleLoader color="white" size="10vh" />
    </Box>
  );
};
