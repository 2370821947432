import {
  Box,
  Divider,
  Grid,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { contactPage } from "../../constants";

type FooterProps = {};

const StyledBox = styled(Box)(() => ({
  marginTop: "2rem",
  padding: "1rem",
  color: "white",
}));

export const Footer = (props: FooterProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <StyledBox>
      <Grid container columns={{ xs: 4, md: 12 }}>
        <Grid
          item
          xs={4}
          sx={{
            textAlign: isMobile ? "center" : "left",
            marginBottom: isMobile ? "2rem" : "0",
          }}
        >
          <Typography variant="h6">Adres</Typography>
          <Typography variant="h6">Ośrodek wypoczynkowy Jodełka</Typography>
          <Typography variant="h6">{contactPage.address}</Typography>
          {isMobile && <Divider />}
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            textAlign: "center",
            marginBottom: isMobile ? "2rem" : "0",
          }}
        >
          <Typography variant="h6">Kontakt</Typography>
          <Typography variant="h6">{contactPage.phoneNumber}</Typography>
          <Typography variant="h6">{contactPage.email}</Typography>
          {isMobile && <Divider />}
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            alignItems: "flex-end",
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-end",
          }}
        >
          <Typography variant="h6">
            Strony internetowe Wojciech Glegoła
          </Typography>
        </Grid>
      </Grid>
    </StyledBox>
  );
};
